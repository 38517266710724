@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */
.container {
	composes: baseContainer from "./main.module.scss";
}

.text{
	margin: 50px 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		margin: 0 50px 5em 50px;
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	@media screen and (max-width: 930px) {
		margin: 30px auto;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
}
.table{
	composes: paragraph;
	border-spacing: 0;
	tr{
		background-color: $cream;
		height: 3em;
	}
	td{
		padding:20px;
	}
	tr:nth-child(even) {
		background-color: $whiteCream;
	}
	@media screen and (max-width: 930px) {
		td{
			padding: 18px 10px;
		}
	}
}
