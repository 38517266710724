@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');


/* autoprefixer grid: on */

.container {
	composes: baseContainer from "./main.module.scss";
	background-color: $cream;
}

.text{
	composes: baseText from "./main.module.scss";
	margin: 50px 35% 5em 0;
	grid-column-start: 2;
	grid-column-end: 3;
	button{
		border-color: $tyrianPurple;
		border-style: solid;
		border-width: 2px;
		font-family: 'Playfair Display', serif;
		font-weight: normal;
		font-size: 24px;
		height: 3em;
		width: 15em;
		margin-top: 30px;
		color: $tyrianPurple;
		background-color: transparent;
	}
	button:hover{
		color: $tyrianPurple;
		border-color: rgba(74, 25, 61, 0.15);
	}
	@media screen and (max-width: 1450px) {
		margin-left: 50px;
		button{
			width: 90%;
		}
	}
	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
		margin-left: 50px;
		margin-right: 50px;
		button{
			margin: 50px auto 0 auto;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90%;
		}
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	@media screen and (max-width: 1450px) {
		//margin-left: auto;
	}
	@media screen and (max-width: 930px) {
		margin-left: auto;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
	margin: 0;
}

.contactEmail{
	composes: headerSimple from "./main.module.scss";
	font-size: 20px;
	display: grid;
	grid-template-columns: 7% 93%;

	svg {
		height: 25px;
		width: 25px;
		opacity: 0.25;
		display: inline;
		margin-right: 10px;
		place-self: center;
	}

	div {
		grid-column-start: 2;
		grid-column-end: 3;
	}

	a {
		display: inline;
		color: $tyrianPurple;
		text-decoration: none;
	}

	margin-left: 90px;
	@media screen and (max-width: 930px) {
		margin-left: 30px;
	}
}

.contactNumber{
	composes: headerSimple from "./main.module.scss";
	font-size: 30px;
	display: grid;
	grid-template-columns: 7% 93%;

	svg{
		height: 30px;
		width: 30px;
		opacity: 0.25;
		display: inline;
		place-self: center;
		margin-right: 10px;
		grid-column-start: 1;
		grid-column-end: 2;
	}

	div {
		grid-column-start: 2;
		grid-column-end: 3;
	}

	a {
		display: inline;
		color: $tyrianPurple;
		text-decoration: none;

	}

	margin-left: 90px;
	margin-top: 20px;
	@media screen and (max-width: 930px) {
		margin-left: 30px;
	}
}
