@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */
.container {
	display: grid;
	grid-template-columns: 35% 65%;

	@media screen and (max-width: 1450px) {
		grid-template-columns: 50% 50%;
	}

	@media screen and (max-width: 930px) {
		grid-template-columns: auto;
	}
}

.button {
	border-color: $tyrianPurple;
	border-style: solid;
	border-width: 2px;
	font-family: 'Playfair Display', serif;
	font-weight: normal;
	font-size: 24px;
	height: 3em;
	width: 15em;
	color: $tyrianPurple;
	background-color: transparent;
	margin-left: 90px;
	margin-bottom: 50px;
	margin-top: 50px;

	@media screen and (max-width: 1450px) {
		margin: 30px auto 30px auto;
	}

	@media screen and (max-width: 930px) {
		margin: 30px auto;
		place-self: center;
	}
}

.header {
	composes: header from "./main.module.scss";
	margin-top: 50px;
	margin-left: 90px;
	margin-right: 50px;

	@media screen and (max-width: 930px) {
		margin: 0;
		place-self: center;
	}
}

.paragraph {
	composes: paragraphSimple from "./main.module.scss";
	font-size: 18px;
}

.littleHeader {
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
}

.text {
	margin: 5em 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;

	ul {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	@media screen and (max-width: 930px) {
		margin: auto 30px;
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.firstColumn {
	margin-left: 50px;

	@media screen and (max-width: 1450px) {
		display: grid;
		grid-template-rows: 300px auto;
		justify-items: start;
	}

	@media screen and (max-width: 930px) {
		grid-template-rows: auto auto;
		margin-left: 0;
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.warning {
	composes: paragraph;
	background-color: $infoBg;
	width: 75%;
	//height: 3em;
	margin: 50px 50px 50px 0;
	color: $info;
	padding: 0.5em 1em 1em 2em;

	p {
		text-decoration: underline;
	}

	h3 {
		font-family: 'Playfair Display', serif;
		font-weight: normal;
		font-size: 24px;
		color: $info;
	}
}
