@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://use.typekit.net/qil5hjr.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://use.typekit.net/qil5hjr.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://use.typekit.net/qil5hjr.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://use.typekit.net/qil5hjr.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://use.typekit.net/qil5hjr.css);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
@import url(https://fonts.googleapis.com/css?family=Playfair+Display);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.socialMedia_container__2cjOF svg {
	height: 20px;
	margin-right: 1em;
	margin-left: 1em;
}

/* autoprefixer grid: on */
.buttons_navigationButton__2t4th {
  border: none;
  color: #4A193D;
  background-color: transparent;
  font-family: futura-pt, sans-serif;
  font-weight: 450;
  font-size: 20px;
  margin: auto 25px; }

.buttons_simpleButton__3KxhK {
  border: none;
  color: #FFFFFF;
  background-color: #4A193D;
  font-family: 'Playfair Display', serif;
  font-size: 24px;
  height: 3em;
  width: 15em; }

/* autoprefixer grid: on */
.navigation_container__1Ow93 {
  height: 100px; }

.navigation_buttons__2eBrB {
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: end;
      justify-self: end;
  margin-right: 50px;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  .navigation_buttons__2eBrB a {
    text-decoration: none; }
  .navigation_buttons__2eBrB p {
    display: inline; }
  @media screen and (max-width: 1450px) {
    .navigation_buttons__2eBrB {
      width: 100vw;
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: auto auto auto auto;
      grid-template-rows: auto auto auto auto;
      margin-top: 100px; }
      .navigation_buttons__2eBrB a {
        margin: 30px auto; } }

.navigation_button__1uT-9 { }

.navigation_logo__1vVGQ {
  margin-left: 0;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row-align: center;
      align-self: center; }
  .navigation_logo__1vVGQ svg {
    width: 200px; }
  @media screen and (min-width: 930px) {
    .navigation_logo__1vVGQ {
      margin-left: 6em;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; }
      .navigation_logo__1vVGQ svg {
        height: 60px;
        width: 300px; } }

.navigation_socialMedia__2vyC0 {
  margin: auto auto;
  display: flex;
  justify-content: center;
  align-items: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (min-width: 1450px) {
    .navigation_socialMedia__2vyC0 {
      -ms-grid-row-align: center;
          align-self: center;
      -ms-grid-column: 3;
          grid-column-start: 3;
      -ms-grid-column-span: 1;
      grid-column-end: 4; } }

.navigation_select__2RnQ1 {
  -ms-grid-row-align: center;
      align-self: center;
  text-transform: capitalize;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  position: relative;
  max-width: 80px; }
  .navigation_select__2RnQ1 svg {
    width: 15px;
    height: 15px;
    margin-left: 10px; }
  @media screen and (min-width: 930px) {
    .navigation_select__2RnQ1 {
      -ms-grid-column: 3;
          grid-column-start: 3;
      -ms-grid-column-span: 1;
      grid-column-end: 4; } }
  @media screen and (min-width: 1450px) {
    .navigation_select__2RnQ1 {
      -ms-grid-column: 4;
          grid-column-start: 4;
      -ms-grid-column-span: 1;
      grid-column-end: 5; } }

:focus {
  outline: none; }

::-moz-focus-inner {
  border: 0; }

.navigation_dropDown__3s78W {
  position: absolute;
  -ms-grid-row-align: center;
      align-self: center;
  margin: 0 0 0 10px;
  background-color: #FFFFFF;
  width: 100%; }
  .navigation_dropDown__3s78W p {
    margin-left: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    opacity: 0.5; }

.navigation_dropDownButton__29YeJ {
  margin: 0 0 0 18px;
  cursor: pointer; }

.navigation_inline__1KKiJ {
  display: inline; }
  .navigation_inline__1KKiJ svg {
    display: inline; }

.navigation_browser__3m-oQ {
  display: none; }
  @media screen and (min-width: 1450px) {
    .navigation_browser__3m-oQ {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 40% 40% 10% 10%;
      grid-template-columns: 40% 40% 10% 10%;
      width: 99vw;
      height: 100px; } }

.navigation_mobile__9vYLL {
  width: 100vw;
  height: 100%;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% 65% 15%;
  grid-template-columns: 20% 65% 15%; }
  @media screen and (min-width: 930px) {
    .navigation_mobile__9vYLL {
      display: none; } }

.navigation_tablet__2XJum {
  display: none; }
  @media screen and (min-width: 930px) {
    .navigation_tablet__2XJum {
      width: 100vw;
      height: 100%;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 65% 15% 10% 10%;
      grid-template-columns: 65% 15% 10% 10%;
      -ms-grid-rows: 100%;
      grid-template-rows: 100%; } }
  @media screen and (min-width: 1450px) {
    .navigation_tablet__2XJum {
      display: none; } }

.navigation_menuButton__2BIkb {
  -ms-grid-row-align: center;
      align-self: center;
  cursor: pointer;
  -ms-grid-column: 3;
      grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4; }
  .navigation_menuButton__2BIkb svg {
    width: 40px;
    fill: none;
    stroke: #4a193d;
    stroke-width: 3px; }
  @media screen and (min-width: 930px) {
    .navigation_menuButton__2BIkb {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.navigation_center__Q9upe {
  -ms-grid-row-align: center;
      align-self: center; }

.navigation_openMenu__3l594 {
  position: fixed;
  background-color: #FFFFFF;
  height: 100vh;
  width: 100vw;
  left: 50%;
  margin-left: -50vw;
  margin-top: -50px;
  opacity: 0.9;
  z-index: 5; }

.navigation_x__2_LiS {
  cursor: pointer;
  position: absolute;
  top: 50%;
  margin-top: -47vh;
  right: 50%;
  margin-right: -47vw; }
  .navigation_x__2_LiS svg {
    fill: none;
    stroke: #4a193d;
    stroke-width: 3px;
    width: 50px; }

/* autoprefixer grid: on */
.footer_container__3YCw3 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 20% 65% 15%;
  grid-template-columns: 20% 65% 15%;
  height: 150px;
  width: 100vw;
  overflow: hidden; }
  @media screen and (max-width: 1450px) {
    .footer_container__3YCw3 {
      height: 380px;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      -ms-grid-columns: 20% 60% 20%;
      grid-template-columns: 20% 60% 20%; } }
  @media screen and (max-width: 930px) {
    .footer_container__3YCw3 {
      -ms-grid-columns: 20% 60% 20%;
      grid-template-columns: 20% 60% 20%; } }

.footer_buttons__3EO0B {
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: end;
      justify-self: end;
  margin-right: 50px;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  .footer_buttons__3EO0B a {
    text-decoration: none; }
  .footer_buttons__3EO0B p {
    display: inline; }
  @media screen and (max-width: 1450px) {
    .footer_buttons__3EO0B {
      margin-top: 30px;
      -ms-grid-row-align: center;
      -ms-grid-column-align: center;
      place-self: center;
      justify-self: center;
      text-align: center;
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: auto auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto auto;
      -ms-grid-column: 2;
          grid-column-start: 2;
      -ms-grid-column-span: 1;
      grid-column-end: 3;
      -ms-grid-row: 1;
          grid-row-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2; }
      .footer_buttons__3EO0B a {
        margin: 0.5em; } }
  @media screen and (max-width: 930px) {
    .footer_buttons__3EO0B {
      margin-right: 0; } }

.footer_button__3mfI_ {
  font-size: 18px; }

.footer_logo__3OcsR {
  -ms-grid-row-align: center;
      align-self: center;
  margin-left: 6em;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2; }
  .footer_logo__3OcsR svg {
    height: 40px; }
  @media screen and (max-width: 1450px) {
    .footer_logo__3OcsR {
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      margin-bottom: 50px; } }
  @media screen and (max-width: 930px) {
    .footer_logo__3OcsR {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      margin-left: 30px; } }

.footer_socialMedia__26sjl {
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 3;
      grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4; }
  @media screen and (max-width: 1450px) {
    .footer_socialMedia__26sjl {
      width: 150%;
      margin-left: -50%;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      -ms-grid-column: 3;
          grid-column-start: 3;
      -ms-grid-column-span: 1;
      grid-column-end: 4;
      margin-bottom: 50px; } }

/* autoprefixer grid: on */
.main_container__2dpZt {
  display: -ms-grid;
  display: grid;
  margin: auto auto;
  background-color: #F4F1ED; }
  @media screen and (min-width: 930px) {
    .main_container__2dpZt {
      -ms-grid-columns: auto;
      grid-template-columns: auto; } }
  @media screen and (min-width: 1450px) {
    .main_container__2dpZt {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%; } }

.main_container__2dpZt svg {
  margin: 50px auto 0 auto;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center;
  max-width: 340px;
  max-height: 340px;
  width: 340px;
  height: 340px; }
  @media screen and (min-width: 450px) {
    .main_container__2dpZt svg {
      max-width: 420px;
      max-height: 420px;
      width: 420px;
      height: 420px; } }
  @media screen and (min-width: 930px) {
    .main_container__2dpZt svg {
      max-width: 500px;
      max-height: 500px;
      width: 500px;
      height: 500px; } }
  @media screen and (min-width: 1450px) {
    .main_container__2dpZt svg {
      margin: 50px;
      max-width: 500px;
      max-height: 500px; } }

.main_bankImage__2lGlT {
  display: -ms-grid;
  display: grid;
  width: 100%;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column-align: center;
      justify-self: center; }

.main_baseText__NFeOg {
  margin: 50px;
  -ms-grid-row-align: center;
      align-self: center; }
  @media screen and (min-width: 930px) {
    .main_baseText__NFeOg {
      margin: 50px 15% 50px 0; } }
  @media screen and (min-width: 1450px) {
    .main_baseText__NFeOg {
      margin: 5em 35% 5em 0; } }

.main_text__3MB8U {
  margin: 50px 15% 50px 15%; }
  @media screen and (min-width: 930px) {
    .main_text__3MB8U {
      -ms-grid-column: 2;
          grid-column-start: 2;
      -ms-grid-column-span: 1;
      grid-column-end: 3; } }
  @media screen and (min-width: 1450px) {
    .main_text__3MB8U {
      margin: 5em 35% 5em 0; } }

.main_headerSimple__Uk-Mk {
  font-family: 'Playfair Display', serif;
  color: #4A193D;
  font-weight: normal; }

.main_headerSimple__Uk-Mk a, a:link {
  font-family: 'Playfair Display', serif;
  color: #4A193D;
  font-weight: normal;
  text-decoration: none; }

.main_header__SP0EX {
  font-size: 55px;
  margin: auto auto;
  text-align: center; }
  @media screen and (min-width: 930px) {
    .main_header__SP0EX {
      text-align: left; } }

.main_paragraphSimple__1X4eU {
  color: #4A193D;
  font-family: futura-pt, sans-serif; }

.main_paragraph__2goxS {
  font-size: 22px; }

.main_button__3Znv6 {
  width: 100%; }
  @media screen and (min-width: 930px) {
    .main_button__3Znv6 {
      width: 15em; } }

.main_button__3Znv6:hover {
  background-color: rgba(74, 25, 61, 0.5); }

.main_littleHeader__1iCl_ {
  margin: 30px 30px 0 30px; }
  @media screen and (min-width: 930px) {
    .main_littleHeader__1iCl_ {
      margin-left: 100px; } }

.main_baseContainer__1pBB6 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
  grid-template-columns: auto; }
  @media screen and (min-width: 930px) {
    .main_baseContainer__1pBB6 {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%; } }
  @media screen and (min-width: 1450px) {
    .main_baseContainer__1pBB6 {
      -ms-grid-columns: 35% 65%;
      grid-template-columns: 35% 65%; } }

/* autoprefixer grid: on */
.mainAbout_container__fxNnc {
  background-color: #FFFFFF; }

.mainAbout_text__UM8_x {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .mainAbout_text__UM8_x {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3; } }

.mainAbout_header__23xtn { }

.mainAbout_paragraph__3KKnG {
  font-size: 20px; }

.mainAbout_littleHeader__3npoX {
  font-size: 40px; }

.mainAbout_containerAboutUs__w3RCJ {
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  margin: 5% 50% 0 20%; }
  @media screen and (max-width: 930px) {
    .mainAbout_containerAboutUs__w3RCJ {
      margin: 50px auto 0 auto; } }

/* autoprefixer grid: on */
.letsTalk_container__2vJe7 {
  background-color: #F4F1ED; }

.letsTalk_text__YzCer {
  margin: 50px 35% 5em 0;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  .letsTalk_text__YzCer button {
    border-color: #4A193D;
    border-style: solid;
    border-width: 2px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    height: 3em;
    width: 15em;
    margin-top: 30px;
    color: #4A193D;
    background-color: transparent; }
  .letsTalk_text__YzCer button:hover {
    color: #4A193D;
    border-color: rgba(74, 25, 61, 0.15); }
  @media screen and (max-width: 1450px) {
    .letsTalk_text__YzCer {
      margin-left: 50px; }
      .letsTalk_text__YzCer button {
        width: 90%; } }
  @media screen and (max-width: 930px) {
    .letsTalk_text__YzCer {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      margin-left: 50px;
      margin-right: 50px; }
      .letsTalk_text__YzCer button {
        margin: 50px auto 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 90%; } }

.letsTalk_header__vJSHP {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px; }
  @media screen and (max-width: 930px) {
    .letsTalk_header__vJSHP {
      margin-left: auto; } }

.letsTalk_paragraph__RbTFu {
  font-size: 20px; }

.letsTalk_littleHeader__3mQy0 {
  font-size: 40px;
  margin: 0; }

.letsTalk_contactEmail__15MK7 {
  font-size: 20px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 7% 93%;
  grid-template-columns: 7% 93%;
  margin-left: 90px; }
  .letsTalk_contactEmail__15MK7 svg {
    height: 25px;
    width: 25px;
    opacity: 0.25;
    display: inline;
    margin-right: 10px;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center; }
  .letsTalk_contactEmail__15MK7 div {
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3; }
  .letsTalk_contactEmail__15MK7 a {
    display: inline;
    color: #4A193D;
    text-decoration: none; }
  @media screen and (max-width: 930px) {
    .letsTalk_contactEmail__15MK7 {
      margin-left: 30px; } }

.letsTalk_contactNumber__RV74L {
  font-size: 30px;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 7% 93%;
  grid-template-columns: 7% 93%;
  margin-left: 90px;
  margin-top: 20px; }
  .letsTalk_contactNumber__RV74L svg {
    height: 30px;
    width: 30px;
    opacity: 0.25;
    display: inline;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center;
    margin-right: 10px;
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2; }
  .letsTalk_contactNumber__RV74L div {
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3; }
  .letsTalk_contactNumber__RV74L a {
    display: inline;
    color: #4A193D;
    text-decoration: none; }
  @media screen and (max-width: 930px) {
    .letsTalk_contactNumber__RV74L {
      margin-left: 30px; } }

/* autoprefixer grid: on */
.discoverOurServices_container__850Bo {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 33.3% 33.3% 33.3%;
  grid-template-columns: 33.3% 33.3% 33.3%;
  -ms-grid-rows: 50% 50%;
  grid-template-rows: 50% 50%; }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_container__850Bo {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
      -ms-grid-rows: 33.3% 33.3% 33.3%;
      grid-template-rows: 33.3% 33.3% 33.3%; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_container__850Bo {
      -ms-grid-columns: auto;
      grid-template-columns: auto;
      -ms-grid-rows: auto auto auto auto auto auto;
      grid-template-rows: auto auto auto auto auto auto; } }

.discoverOurServices_headerCointainer__2hXOQ {
  background-color: #F4F1ED;
  color: #4A193D;
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }
  .discoverOurServices_headerCointainer__2hXOQ button {
    -ms-grid-row: 2;
        grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3;
    border-color: #4A193D;
    border-style: solid;
    border-width: 2px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    height: 3em;
    width: 10em;
    color: #4A193D;
    margin: 150px 2em 2em 100px;
    background-color: transparent; }
  .discoverOurServices_headerCointainer__2hXOQ button:hover {
    color: #4A193D;
    border-color: rgba(74, 25, 61, 0.15); }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_headerCointainer__2hXOQ h1 {
      margin: 50px 2em; }
    .discoverOurServices_headerCointainer__2hXOQ button {
      margin: 150px auto 50px auto; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_headerCointainer__2hXOQ h1 {
      margin: 50px auto; }
    .discoverOurServices_headerCointainer__2hXOQ button {
      margin: 150px auto 2em auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%; } }

.discoverOurServices_image__uxTQn {
  position: absolute; }
  .discoverOurServices_image__uxTQn svg {
    height: 100px;
    opacity: 0.05;
    -ms-grid-row: 1;
        grid-row-start: 1;
    -ms-grid-row-span: 2;
    grid-row-end: 3;
    position: absolute;
    margin: 40px 0 0 50px; }

.discoverOurServices_serviceContainer__2rcAl {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: 33% 33% 33%;
  grid-template-rows: 33% 33% 33%;
  min-height: 500px;
  height: 500px;
  color: #4A193D; }
  .discoverOurServices_serviceContainer__2rcAl h1 {
    -ms-grid-row: 1;
        grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2; }
  .discoverOurServices_serviceContainer__2rcAl p {
    -ms-grid-row: 2;
        grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3; }
  .discoverOurServices_serviceContainer__2rcAl button {
    color: #4A193D;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    background-color: transparent;
    border: none;
    text-align: left;
    margin: 0 2em 0 100px;
    -ms-grid-row: 3;
        grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4; }
    .discoverOurServices_serviceContainer__2rcAl button p {
      display: inline; }
  .discoverOurServices_serviceContainer__2rcAl button::before {
    content: " ";
    background-image: url(/static/media/arrow.13819926.svg);
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: 7em 0.4em;
    opacity: 0.25;
    position: absolute;
    width: 20%;
    height: 20%; }

.discoverOurServices_whiteContainer__kQ_JN {
  background-color: #FCFCFB;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }
  @media screen and (max-width: 930px) {
    .discoverOurServices_whiteContainer__kQ_JN {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3; } }

.discoverOurServices_whiteContainer2__1ODiI {
  background-color: #FCFCFB;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 2;
      grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3; }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_whiteContainer2__1ODiI {
      -ms-grid-column: 2;
          grid-column-start: 2;
      -ms-grid-column-span: 1;
      grid-column-end: 3;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_whiteContainer2__1ODiI {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 4;
          grid-row-start: 4;
      -ms-grid-row-span: 1;
      grid-row-end: 5; } }

.discoverOurServices_whiteContainer3__32fbY {
  background-color: #FCFCFB;
  -ms-grid-column: 3;
      grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 2;
      grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3; }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_whiteContainer3__32fbY {
      -ms-grid-column: 2;
          grid-column-start: 2;
      -ms-grid-column-span: 1;
      grid-column-end: 3;
      -ms-grid-row: 3;
          grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_whiteContainer3__32fbY {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 6;
          grid-row-start: 6;
      -ms-grid-row-span: 1;
      grid-row-end: 7; } }

.discoverOurServices_creamContainer2__2ay26 {
  background-color: #F4F1ED;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 2;
      grid-row-start: 2;
  -ms-grid-row-span: 1;
  grid-row-end: 3; }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_creamContainer2__2ay26 {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 3;
          grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_creamContainer2__2ay26 {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 5;
          grid-row-start: 5;
      -ms-grid-row-span: 1;
      grid-row-end: 6; } }

.discoverOurServices_creamContainer__1MCgx {
  background-color: #F4F1ED;
  -ms-grid-column: 3;
      grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }
  @media screen and (max-width: 1450px) {
    .discoverOurServices_creamContainer__1MCgx {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3; } }
  @media screen and (max-width: 930px) {
    .discoverOurServices_creamContainer__1MCgx {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 3;
          grid-row-start: 3;
      -ms-grid-row-span: 1;
      grid-row-end: 4; } }

.discoverOurServices_header__3TEFV {
  margin: auto 50px; }
  @media screen and (min-width: 930px) {
    .discoverOurServices_header__3TEFV {
      text-align: center; } }
  @media screen and (min-width: 1450px) {
    .discoverOurServices_header__3TEFV {
      margin: 50px 30px 0 90px;
      text-align: left; } }

.discoverOurServices_paragraph__3lAhJ {
  margin: 60px 150px 0 100px; }
  @media screen and (max-width: 1000px) {
    .discoverOurServices_paragraph__3lAhJ {
      margin: auto 30px 0 30px; } }
  @media screen and (max-width: 400px) {
    .discoverOurServices_paragraph__3lAhJ {
      margin: auto 50px 0 0; } }

.discoverOurServices_littleHeader__1Hiss {
  margin: 1em 130px 0 100px; }
  @media screen and (max-width: 1400px) {
    .discoverOurServices_littleHeader__1Hiss {
      margin: 1em 90px 0 90px; } }
  @media screen and (max-width: 1000px) {
    .discoverOurServices_littleHeader__1Hiss {
      margin: 1em 20px 0 20px; } }
  @media screen and (max-width: 400px) {
    .discoverOurServices_littleHeader__1Hiss {
      margin: auto 50px; } }

/* autoprefixer grid: on */
.blogPreview_container__MVK55 {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 5% 30% 30% 30% 5%;
  grid-template-columns: 5% 30% 30% 30% 5%;
  background-color: #FFFFFF; }
  .blogPreview_container__MVK55 svg {
    height: 60px;
    width: 30px;
    -ms-grid-row-align: center;
    -ms-grid-column-align: center;
    place-self: center;
    opacity: 0.25; }
  @media screen and (max-width: 1450px) {
    .blogPreview_container__MVK55 {
      -ms-grid-columns: 5% 45% 45% 5%;
      grid-template-columns: 5% 45% 45% 5%; } }
  @media screen and (max-width: 930px) {
    .blogPreview_container__MVK55 {
      -ms-grid-columns: 15% 70% 15%;
      grid-template-columns: 15% 70% 15%; } }

.blogPreview_header__1j8pc {
  margin-top: 50px;
  margin-left: 90px; }

.blogPreview_paragraph__3vxQZ {
  font-size: 20px; }

.blogPreview_littleHeader__oNuCJ {
  font-size: 32px; }

.blogPreview_littleHeader__oNuCJ a:link {
  text-decoration: none; }

.blogPreview_blogPostContainer__MF7b1 {
  margin: 60px 60px; }
  @media screen and (max-width: 1450px) {
    .blogPreview_blogPostContainer__MF7b1 {
      margin: 60px; } }
  @media screen and (max-width: 930px) {
    .blogPreview_blogPostContainer__MF7b1 {
      margin: 0px; } }

.blogPreview_readMoreContainer__1I5gY {
  background-color: #F4F1ED;
  margin-bottom: 60px; }

.blogPreview_blog__2zsoT {
  margin: 50px 0; }

.blogPreview_hover__vfuQg {
  padding: 1px 0; }

.blogPreview_hover__vfuQg:hover {
  background-color: #F4F1ED; }
  .blogPreview_hover__vfuQg:hover div::after {
    /*content: "Read more";*/
    font-size: 18px;
    font-family: 'Playfair Display', serif;
    color: #4A193D;
    font-weight: normal;
    cursor: pointer; }

.blogPreview_post1__2v3Wl {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }

.blogPreview_post2__1Q4Wy {
  -ms-grid-column: 3;
      grid-column-start: 3;
  -ms-grid-column-span: 1;
  grid-column-end: 4;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }
  @media screen and (max-width: 930px) {
    .blogPreview_post2__1Q4Wy {
      display: none; } }

.blogPreview_post3__jmLWo {
  -ms-grid-column: 4;
      grid-column-start: 4;
  -ms-grid-column-span: 1;
  grid-column-end: 5;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2; }
  @media screen and (max-width: 1450px) {
    .blogPreview_post3__jmLWo {
      display: none; } }

.blogPreview_arrowLeft__1m5uE {
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  place-self: center;
  display: -ms-grid;
  display: grid; }

.blogPreview_arrowRight__3ow9E {
  -ms-grid-row: 1;
      grid-row-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-column: 5;
      grid-column-start: 5;
  -ms-grid-column-span: 1;
  grid-column-end: 6;
  -ms-grid-row-align: center;
  -ms-grid-column-align: center;
  place-self: center;
  display: -ms-grid;
  display: grid; }
  @media screen and (max-width: 1450px) {
    .blogPreview_arrowRight__3ow9E {
      -ms-grid-column: 4;
          grid-column-start: 4;
      -ms-grid-column-span: 1;
      grid-column-end: 5; } }
  @media screen and (max-width: 930px) {
    .blogPreview_arrowRight__3ow9E {
      -ms-grid-column: 3;
          grid-column-start: 3;
      -ms-grid-column-span: 1;
      grid-column-end: 4; } }

/* autoprefixer grid: on */
.aboutUs_container__3hS9_ {
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto; }

.aboutUs_container1__2QZYv {
  background-color: #F4F1ED; }

.aboutUs_container2__3wthu {
  background-color: #FFFFFF; }

.aboutUs_aboutUsContainer__1RRs2 {
  -ms-grid-row-align: center;
      align-self: center; }
  .aboutUs_aboutUsContainer__1RRs2 svg {
    height: 120px;
    opacity: 0.05;
    position: absolute;
    margin: 7em 0 0 12em; }
  @media screen and (max-width: 930px) {
    .aboutUs_aboutUsContainer__1RRs2 {
      -ms-grid-row-align: start;
          align-self: start;
      -ms-grid-column-align: center;
          justify-self: center;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 1;
          grid-row-start: 1;
      -ms-grid-row-span: 1;
      grid-row-end: 2;
      margin: auto 50px;
      text-align: center; }
      .aboutUs_aboutUsContainer__1RRs2 svg {
        height: 120px;
        opacity: 0.05;
        position: absolute;
        margin: 100px 0 0 40px; } }

.aboutUs_text__35IJK {
  margin: 5em 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .aboutUs_text__35IJK {
      -ms-grid-row-align: start;
          align-self: start;
      -ms-grid-column-align: center;
          justify-self: center;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      -ms-grid-row: 2;
          grid-row-start: 2;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      margin: auto 50px; } }

.aboutUs_header__Ihc3R {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px; }
  @media screen and (max-width: 930px) {
    .aboutUs_header__Ihc3R {
      margin-left: 0; } }

.aboutUs_paragraph__1tssy {
  font-size: 18px; }

.aboutUs_littleHeader__1i2f8 {
  font-size: 40px; }

/* autoprefixer grid: on */
.service_container__19xdT { }

.service_mainContainer__21HuM {
  background-color: #F4F1ED; }
  .service_mainContainer__21HuM button {
    border-color: #4A193D;
    border-style: solid;
    border-width: 2px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    height: 3em;
    width: 15em;
    margin-top: 30px;
    color: #4A193D;
    background-color: transparent;
    margin-left: 90px;
    margin-bottom: 50px; }
  .service_mainContainer__21HuM svg {
    height: 100px;
    opacity: 0.1;
    position: absolute;
    margin: -150px 0 0 340px; }
  @media screen and (max-width: 1450px) {
    .service_mainContainer__21HuM svg {
      margin: 160px 0 0 270px; }
    .service_mainContainer__21HuM button {
      width: 12em;
      margin: auto;
      -ms-grid-row-align: center;
      -ms-grid-column-align: center;
      place-self: center; } }
  @media screen and (max-width: 930px) {
    .service_mainContainer__21HuM {
      margin: 0 0 0 0;
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: auto;
      grid-template-columns: auto;
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; }
      .service_mainContainer__21HuM button {
        margin: auto;
        -ms-grid-row-align: center;
        -ms-grid-column-align: center;
        place-self: center;
        width: 15em; }
      .service_mainContainer__21HuM svg {
        margin: 80px 0 0 50px; } }

@media screen and (max-width: 1450px) {
  .service_headerText__BDJ8i {
    display: -ms-grid;
    display: grid;
    position: relative;
    -ms-grid-rows: 50% 50%;
    grid-template-rows: 50% 50%; } }

@media screen and (max-width: 930px) {
  .service_headerText__BDJ8i {
    -ms-grid-row: 1;
        grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    display: -ms-grid;
    display: grid;
    position: relative; } }

.service_servicesContainer__2JVJB {
  margin-right: 50px; }

.service_header__1Ejr_ {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px;
  margin-right: 50px; }
  @media screen and (max-width: 930px) {
    .service_header__1Ejr_ {
      margin-left: 50px; } }

.service_paragraph__1d1ia {
  font-size: 18px; }
  @media screen and (max-width: 930px) {
    .service_paragraph__1d1ia {
      margin: 30px 50px; } }

.service_littleHeader__SjR2i {
  font-size: 40px; }
  @media screen and (max-width: 930px) {
    .service_littleHeader__SjR2i {
      margin: 30px 50px; } }

.service_text__f7jLj {
  margin: 5em 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .service_text__f7jLj {
      -ms-grid-row: 2;
          grid-row-start: 2;
      margin: auto auto;
      -ms-grid-row-span: 1;
      grid-row-end: 3;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      margin-bottom: 30px; } }

.service_countries__3HLdH {
  list-style-type: none; }
  .service_countries__3HLdH a {
    color: #4A193D;
    text-decoration: none; }

.service_firstColumn__-QsFX {
  margin-left: 50px; }
  @media screen and (min-width: 1450px) {
    .service_firstColumn__-QsFX {
      margin-right: 100px; } }

.service_table__3I9Lg {
  border-spacing: 0; }
  .service_table__3I9Lg tr {
    background-color: #F4F1ED;
    height: 3em; }
  .service_table__3I9Lg td {
    padding: 20px; }
  .service_table__3I9Lg tr:nth-child(even) {
    background-color: #FCFCFB; }
  @media screen and (max-width: 930px) {
    .service_table__3I9Lg td {
      padding: 18px 10px; } }

.service_warning__qM4Hq {
  background-color: #EDF3F4;
  width: 75%;
  margin: 50px 50px 50px 0;
  color: #364648; }
  .service_warning__qM4Hq p {
    padding: 0.8em 0 1em 1.5em; }
  @media screen and (max-width: 930px) {
    .service_warning__qM4Hq {
      margin: 30px 50px;
      width: 90%; } }

.service_secondColumn__2VIrX {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .service_secondColumn__2VIrX {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

/* autoprefixer grid: on */
.blog_container__17hJe {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 33% 33% 33%;
  grid-template-columns: 33% 33% 33%;
  margin: 40px; }
  .blog_container__17hJe :nth-child(3n+1) {
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2; }
  .blog_container__17hJe :nth-child(3n+2) {
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3; }
  .blog_container__17hJe :nth-child(3n+3) {
    -ms-grid-column: 3;
        grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4; }
  .blog_container__17hJe :nth-child(4), .blog_container__17hJe :nth-child(5), .blog_container__17hJe :nth-child(6) {
    -ms-grid-row: 2;
        grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3; }
  .blog_container__17hJe :nth-child(7), .blog_container__17hJe :nth-child(8), .blog_container__17hJe :nth-child(9) {
    -ms-grid-row: 3;
        grid-row-start: 3;
    -ms-grid-row-span: 1;
    grid-row-end: 4; }
  @media screen and (max-width: 1450px) {
    .blog_container__17hJe {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%; }
      .blog_container__17hJe :nth-child(2n+1) {
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2; }
      .blog_container__17hJe :nth-child(2n+2) {
        -ms-grid-column: 2;
            grid-column-start: 2;
        -ms-grid-column-span: 1;
        grid-column-end: 3; }
      .blog_container__17hJe :nth-child(3), .blog_container__17hJe :nth-child(4) {
        -ms-grid-row: 2;
            grid-row-start: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 3; }
      .blog_container__17hJe :nth-child(5), .blog_container__17hJe :nth-child(6) {
        -ms-grid-row: 3;
            grid-row-start: 3;
        -ms-grid-row-span: 1;
        grid-row-end: 4; }
      .blog_container__17hJe :nth-child(8), .blog_container__17hJe :nth-child(7) {
        -ms-grid-row: 4;
            grid-row-start: 4;
        -ms-grid-row-span: 1;
        grid-row-end: 5; }
      .blog_container__17hJe :nth-child(9) {
        -ms-grid-row: 6;
            grid-row-start: 6;
        -ms-grid-row-span: 1;
        grid-row-end: 7; } }
  @media screen and (max-width: 930px) {
    .blog_container__17hJe {
      -ms-grid-columns: auto;
      grid-template-columns: auto;
      margin: 20px; }
      .blog_container__17hJe :nth-child(n+1) {
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2;
        -ms-grid-row: auto;
            grid-row-start: auto;
        -ms-grid-row-span: NaN;
        grid-row-end: auto; } }

.blog_header__2RB9R {
  margin-top: 50px;
  margin-left: 90px; }
  @media screen and (max-width: 930px) {
    .blog_header__2RB9R {
      margin: 30px auto 0 auto; } }

/* autoprefixer grid: on */
.contact_container__m0RkF {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 35% 65%;
  grid-template-columns: 35% 65%; }
  @media screen and (max-width: 1450px) {
    .contact_container__m0RkF {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%; } }
  @media screen and (max-width: 930px) {
    .contact_container__m0RkF {
      -ms-grid-columns: 100%;
      grid-template-columns: 100%; } }

.contact_contactUsContainer__3ZBtY {
  background-color: #F4F1ED; }

.contact_letsTalkContainer__2aDRA { }
  .contact_letsTalkContainer__2aDRA :first-child {
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2; }

.contact_email__LyPJQ {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }

.contact_number__1LI3k {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }

.contact_second__3BmYI {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .contact_second__3BmYI {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.contact_decidingContainer__2dltC {
  background-color: #F4F1ED; }

.contact_contactPicture__Kyfok {
  margin-right: 600px;
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  position: relative; }
  .contact_contactPicture__Kyfok svg {
    position: absolute;
    height: 120px;
    width: 150px;
    margin: 110px 0 0 160px;
    z-index: 0; }
  .contact_contactPicture__Kyfok h1 {
    position: relative; }

.contact_text__9NtgS {
  margin: 5em 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  .contact_text__9NtgS button {
    border-color: #4A193D;
    border-style: solid;
    border-width: 2px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    height: 3em;
    width: 15em;
    margin-top: 30px;
    color: #4A193D;
    background-color: transparent; }
  @media screen and (max-width: 1450px) {
    .contact_text__9NtgS {
      margin: 50px 30px 50px 0; } }
  @media screen and (max-width: 930px) {
    .contact_text__9NtgS {
      margin: 0 30px 5em 30px;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.contact_header__qKR4o {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px;
  z-index: 3;
  margin-right: 500px; }
  @media screen and (max-width: 930px) {
    .contact_header__qKR4o {
      margin: 30px 30px 10px 30px;
      text-align: center; } }

.contact_paragraph__1nXnI {
  font-size: 20px; }

.contact_littleHeader__VYQPH {
  font-size: 40px; }
  @media screen and (max-width: 930px) {
    .contact_littleHeader__VYQPH {
      margin: 30px;
      text-align: center; } }

.contact_contactEmail__1R1Xn {
  margin-right: 50px; }

.contact_contactNumber__3_Kot {
  margin-right: 50px; }

.contact_form__kAZ15 {
  margin: 50px 0; }
  .contact_form__kAZ15 input {
    border: none;
    background-color: #F4F1ED;
    color: #4A193D;
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    width: 500px;
    height: 3em;
    margin: 15px;
    padding: 0 0 0 25px; }
  .contact_form__kAZ15 textarea {
    border: none;
    background-color: #F4F1ED;
    color: #4A193D;
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    width: 100%;
    height: 24em;
    margin: 15px;
    padding: 1em 0 0 25px;
    resize: none;
    overflow: auto; }
  .contact_form__kAZ15 ::-webkit-input-placeholder {
    opacity: 1;
    color: #4A193D; }
  .contact_form__kAZ15 :-ms-input-placeholder {
    opacity: 1;
    color: #4A193D; }
  .contact_form__kAZ15 ::-ms-input-placeholder {
    opacity: 1;
    color: #4A193D; }
  .contact_form__kAZ15 ::placeholder {
    opacity: 1;
    color: #4A193D; }
  .contact_form__kAZ15 button {
    border: none;
    background-color: #4A193D;
    color: #FFFFFF;
    font-family: 'Playfair Display', serif;
    font-size: 24px;
    height: 3em;
    width: 525px;
    margin: 15px; }
  @media screen and (max-width: 1450px) {
    .contact_form__kAZ15 {
      margin: 30px 0; }
      .contact_form__kAZ15 input {
        width: 330px; }
      .contact_form__kAZ15 textarea {
        width: 330px; }
      .contact_form__kAZ15 button {
        width: 355px; } }
  @media screen and (max-width: 930px) {
    .contact_form__kAZ15 {
      margin: 30px 30px; }
      .contact_form__kAZ15 input {
        width: 80%; }
      .contact_form__kAZ15 textarea {
        width: 80%; }
      .contact_form__kAZ15 button {
        width: 80%; } }

.contact_servicesContainer__2JT4B {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 33% 33% 33%;
  grid-template-columns: 33% 33% 33%;
  -ms-grid-rows: 50% 50%;
  grid-template-rows: 50% 50%;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  font-size: 28px;
  color: #4A193D;
  position: relative; }
  .contact_servicesContainer__2JT4B :nth-child(3n+1) {
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2; }
  .contact_servicesContainer__2JT4B :nth-child(3n+2) {
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3; }
  .contact_servicesContainer__2JT4B :nth-child(3n+3) {
    -ms-grid-column: 3;
        grid-column-start: 3;
    -ms-grid-column-span: 1;
    grid-column-end: 4; }
  .contact_servicesContainer__2JT4B :nth-child(4), .contact_servicesContainer__2JT4B :nth-child(5) {
    -ms-grid-row: 2;
        grid-row-start: 2;
    -ms-grid-row-span: 1;
    grid-row-end: 3; }
  .contact_servicesContainer__2JT4B div {
    margin: 30px; }
  .contact_servicesContainer__2JT4B svg {
    height: 95px;
    width: 95px;
    opacity: 0.05;
    position: absolute;
    margin: -10px 0 0 -40px; }
  @media screen and (max-width: 1450px) {
    .contact_servicesContainer__2JT4B {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%;
      -ms-grid-rows: 33% 33% 33%;
      grid-template-rows: 33% 33% 33%; }
      .contact_servicesContainer__2JT4B :nth-child(2n+1) {
        -ms-grid-column: 1;
            grid-column-start: 1;
        -ms-grid-column-span: 1;
        grid-column-end: 2; }
      .contact_servicesContainer__2JT4B :nth-child(2n+2) {
        -ms-grid-column: 2;
            grid-column-start: 2;
        -ms-grid-column-span: 1;
        grid-column-end: 3; }
      .contact_servicesContainer__2JT4B :nth-child(3), .contact_servicesContainer__2JT4B :nth-child(4) {
        -ms-grid-row: 2;
            grid-row-start: 2;
        -ms-grid-row-span: 1;
        grid-row-end: 3; }
      .contact_servicesContainer__2JT4B :nth-child(5) {
        -ms-grid-row: 3;
            grid-row-start: 3;
        -ms-grid-row-span: 1;
        grid-row-end: 4; } }

/* autoprefixer grid: on */
.termsAndConditions_container__B9jVW { }

.termsAndConditions_text__1O7E9 {
  margin: 50px 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .termsAndConditions_text__1O7E9 {
      margin: 0 50px 5em 50px;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.termsAndConditions_header__3pdmJ {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px; }
  @media screen and (max-width: 930px) {
    .termsAndConditions_header__3pdmJ {
      margin: 30px auto; } }

.termsAndConditions_paragraph__1wZVt {
  font-size: 20px; }

.termsAndConditions_littleHeader__2FLNj {
  font-size: 40px; }

.termsAndConditions_table__cwa_V {
  border-spacing: 0; }
  .termsAndConditions_table__cwa_V tr {
    background-color: #F4F1ED;
    height: 3em; }
  .termsAndConditions_table__cwa_V td {
    padding: 20px; }
  .termsAndConditions_table__cwa_V tr:nth-child(even) {
    background-color: #FCFCFB; }
  @media screen and (max-width: 930px) {
    .termsAndConditions_table__cwa_V td {
      padding: 18px 10px; } }

/* autoprefixer grid: on */
.faq_container__3kV5L { }

.faq_container1__1TTlp {
  background-color: #F4F1ED; }

.faq_container2__orxrv {
  background-color: #FFFFFF;
  margin: 3% 0 5% 3%; }
  @media screen and (max-width: 1450px) {
    .faq_container2__orxrv {
      -ms-grid-columns: auto;
      grid-template-columns: auto; } }

.faq_faqContainer__1nq4U {
  -ms-grid-row-align: center;
      align-self: center;
  margin: auto 30px; }
  .faq_faqContainer__1nq4U svg {
    height: 210px;
    opacity: 0.05;
    position: absolute;
    margin: 8em 0 0 15em; }
  @media screen and (max-width: 930px) {
    .faq_faqContainer__1nq4U svg {
      margin: 30px 0 0 50%; } }

.faq_text__2L0xU {
  margin: 5em 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 930px) {
    .faq_text__2L0xU {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2;
      margin: 30px 50px; } }

.faq_header__aaISN {
  margin-bottom: 100px;
  margin-top: 50px;
  margin-left: 90px; }
  @media screen and (max-width: 930px) {
    .faq_header__aaISN {
      margin: 50px auto;
      display: block; } }

.faq_paragraph__2WDo5 {
  font-size: 18px; }

.faq_category__1FvvR {
  font-size: 20px; }
  @media screen and (max-width: 1450px) {
    .faq_category__1FvvR {
      display: none; } }

.faq_categoryDropDown__3BP3b {
  display: none; }
  @media screen and (max-width: 1450px) {
    .faq_categoryDropDown__3BP3b {
      display: block;
      margin-top: 30px; } }
  @media screen and (max-width: 930px) {
    .faq_categoryDropDown__3BP3b {
      margin-top: 0; } }

@media screen and (max-width: 1450px) {
  .faq_dropDownContainer__1A_RA {
    font-size: 20px;
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 60% 40%;
    grid-template-columns: 60% 40%; } }

@media screen and (max-width: 930px) {
  .faq_dropDownContainer__1A_RA {
    -ms-grid-columns: auto;
    grid-template-columns: auto;
    text-align: center; } }

.faq_dropDown__2a_CS {
  position: absolute;
  opacity: 0.5;
  background-color: #FFFFFF;
  width: 200px; }
  @media screen and (max-width: 930px) {
    .faq_dropDown__2a_CS {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0; } }

.faq_littleHeader__7Jskc {
  font-size: 40px;
  margin-bottom: 1em; }
  @media screen and (max-width: 930px) {
    .faq_littleHeader__7Jskc {
      text-align: center; } }

.faq_questions__18THZ {
  margin-right: 5em; }
  .faq_questions__18THZ h2 {
    font-family: 'Playfair Display', serif;
    color: #4A193D;
    font-weight: normal;
    font-size: 20px;
    margin-left: 40px; }
  .faq_questions__18THZ p {
    color: #4A193D;
    font-family: futura-pt, sans-serif;
    font-size: 18px;
    margin-left: 40px;
    margin-right: 80px; }
  @media screen and (max-width: 930px) {
    .faq_questions__18THZ {
      margin: auto 50px; } }

.faq_button__3RWep {
  background-color: #F4F1ED;
  border: none;
  width: 100%;
  text-align: left;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 90% auto;
  grid-template-columns: 90% auto;
  margin-bottom: 1em; }
  .faq_button__3RWep p {
    font-family: 'Playfair Display', serif;
    color: #4A193D;
    font-weight: normal;
    font-size: 20px;
    margin: 30px;
    -ms-grid-column: 1;
        grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: 2; }
  .faq_button__3RWep svg {
    min-height: 8px;
    height: 8px;
    width: 15px;
    min-width: 15px;
    margin-right: 1em;
    margin-top: auto;
    margin-bottom: auto;
    -ms-grid-column-align: end;
        justify-self: end;
    -ms-grid-column: 2;
        grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: 3; }
  @media screen and (max-width: 400px) {
    .faq_button__3RWep {
      width: 100%;
      margin-bottom: 1em; } }

.faq_first__3BlxP {
  -ms-grid-column: 1;
      grid-column-start: 1;
  -ms-grid-column-span: 1;
  grid-column-end: 2; }

.faq_second__1zHD7 {
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  @media screen and (max-width: 1450px) {
    .faq_second__1zHD7 {
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

/* autoprefixer grid: on */
.serviceForCountry_container__38GGu {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 35% 65%;
  grid-template-columns: 35% 65%; }
  @media screen and (max-width: 1450px) {
    .serviceForCountry_container__38GGu {
      -ms-grid-columns: 50% 50%;
      grid-template-columns: 50% 50%; } }
  @media screen and (max-width: 930px) {
    .serviceForCountry_container__38GGu {
      -ms-grid-columns: auto;
      grid-template-columns: auto; } }

.serviceForCountry_button__2Gpof {
  border-color: #4A193D;
  border-style: solid;
  border-width: 2px;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  font-size: 24px;
  height: 3em;
  width: 15em;
  color: #4A193D;
  background-color: transparent;
  margin-left: 90px;
  margin-bottom: 50px;
  margin-top: 50px; }
  @media screen and (max-width: 1450px) {
    .serviceForCountry_button__2Gpof {
      margin: 30px auto 30px auto; } }
  @media screen and (max-width: 930px) {
    .serviceForCountry_button__2Gpof {
      margin: 30px auto;
      -ms-grid-row-align: center;
      -ms-grid-column-align: center;
      place-self: center; } }

.serviceForCountry_header__ntHAq {
  margin-top: 50px;
  margin-left: 90px;
  margin-right: 50px; }
  @media screen and (max-width: 930px) {
    .serviceForCountry_header__ntHAq {
      margin: 0;
      -ms-grid-row-align: center;
      -ms-grid-column-align: center;
      place-self: center; } }

.serviceForCountry_paragraph__1u67J {
  font-size: 18px; }

.serviceForCountry_littleHeader__1KyIV {
  font-size: 40px; }

.serviceForCountry_text__3no8T {
  margin: 5em 35% 5em 0;
  -ms-grid-row-align: center;
      align-self: center;
  -ms-grid-column: 2;
      grid-column-start: 2;
  -ms-grid-column-span: 1;
  grid-column-end: 3; }
  .serviceForCountry_text__3no8T ul {
    list-style-type: none;
    margin: 0;
    padding: 0; }
  @media screen and (max-width: 930px) {
    .serviceForCountry_text__3no8T {
      margin: auto 30px;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.serviceForCountry_firstColumn__3b2Le {
  margin-left: 50px; }
  @media screen and (max-width: 1450px) {
    .serviceForCountry_firstColumn__3b2Le {
      display: -ms-grid;
      display: grid;
      -ms-grid-rows: 300px auto;
      grid-template-rows: 300px auto;
      justify-items: start; } }
  @media screen and (max-width: 930px) {
    .serviceForCountry_firstColumn__3b2Le {
      -ms-grid-rows: auto auto;
      grid-template-rows: auto auto;
      margin-left: 0;
      -ms-grid-column: 1;
          grid-column-start: 1;
      -ms-grid-column-span: 1;
      grid-column-end: 2; } }

.serviceForCountry_warning__3T41t {
  background-color: #EDF3F4;
  width: 75%;
  margin: 50px 50px 50px 0;
  color: #364648;
  padding: 0.5em 1em 1em 2em; }
  .serviceForCountry_warning__3T41t p {
    text-decoration: underline; }
  .serviceForCountry_warning__3T41t h3 {
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    color: #364648; }

/* autoprefixer grid: on */
.blogPost_container__gGD6b {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
  grid-template-columns: auto; }
  @media screen and (min-width: 930px) {
    .blogPost_container__gGD6b {
      -ms-grid-columns: 35% 65%;
      grid-template-columns: 35% 65%; } }
  @media screen and (max-width: 1450px) {
    .blogPost_container__gGD6b {
      -ms-grid-columns: auto;
      grid-template-columns: auto; } }

@media screen and (max-width: 1450px) {
  .blogPost_headerContainer__1jmlz {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 65% 35%;
    grid-template-columns: 65% 35%; } }

@media screen and (max-width: 930px) {
  .blogPost_headerContainer__1jmlz {
    -ms-grid-columns: auto;
    grid-template-columns: auto; } }

.blogPost_text__1hTtU {
  margin: 5em 35% 5em 5em;
  -ms-grid-row-align: center;
      align-self: center;
  text-align: justify; }
  @media screen and (max-width: 1450px) {
    .blogPost_text__1hTtU {
      margin: 50px 50px; } }

.blogPost_header__3t90H {
  margin-top: 50px;
  margin-left: 100px; }
  @media screen and (max-width: 930px) {
    .blogPost_header__3t90H {
      margin-left: 50px;
      margin-right: 50px;
      text-align: center; } }

.blogPost_paragraph__1kDuK {
  font-size: 18px; }

.blogPost_littleHeader__2NFKi {
  font-size: 40px; }

.blogPost_headerImage__1aBNY {
  background-image: url(/static/media/Stairs-to-tax-heaven-1920x400.21e0ca0c.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 85% 65%;
  width: 100%;
  height: 400px; }

.blogPost_headerImagePostTwo__1AdA2 {
  background-image: url(/static/media/Offshore-bank-1920x400.77d40044.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 55%;
  width: 100%;
  height: 400px; }

.blogPost_headerImagePostThree__SWLQm {
  background-image: url(/static/media/Rejected-by-banks-1920x400.d6b0a22b.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 15% 30%;
  width: 100%;
  height: 400px; }

.blogPost_headerImagePostFour__3mLX7 {
  background-image: url(/static/media/Offshore-company-1920x400.17ddf79d.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 85%;
  width: 100%;
  height: 400px; }

.blogPost_author__1Qb-f {
  opacity: 0.48;
  margin-left: 100px; }
  @media screen and (max-width: 930px) {
    .blogPost_author__1Qb-f {
      margin-left: 50px;
      margin-right: 50px;
      text-align: center; } }

.blogPost_shareButton__2eMac {
  display: block;
  border-color: #4A193D;
  border-style: solid;
  border-width: 2px;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  font-size: 24px;
  height: 3em;
  width: 10em;
  color: #4A193D;
  margin: 100px 0 30px 100px;
  background-color: transparent; }
  .blogPost_shareButton__2eMac:hover {
    border-color: rgba(74, 25, 61, 0.15); }
  @media screen and (max-width: 930px) {
    .blogPost_shareButton__2eMac {
      margin: 30px auto;
      text-align: center; } }

.blogPost_backButton__3qVif {
  color: #4A193D;
  font-family: 'Playfair Display', serif;
  font-weight: normal;
  font-size: 24px;
  background-color: transparent;
  border: none;
  text-align: left;
  margin: 0 0 0 100px; }
  .blogPost_backButton__3qVif p {
    display: inline;
    margin-left: 50px; }
  .blogPost_backButton__3qVif::before {
    content: " ";
    background-image: url(/static/media/arrow-back.981eb621.svg);
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: 0 0.4em;
    opacity: 0.25;
    position: absolute;
    width: 100%;
    height: 100%; }
  @media screen and (max-width: 930px) {
    .blogPost_backButton__3qVif {
      margin: 30px auto;
      text-align: center;
      display: block; } }

.blogPost_postImage__oyOE- {
  background-image: url(/static/media/money.9909e877.jpg);
  width: 100%;
  height: 400px; }

body {
	overflow-x: hidden;
}

