@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */

.container {
	display: grid;
	grid-template-columns: 5% 30% 30% 30% 5%;
	background-color: $white;
	svg{
		height: 60px;
		width: 30px;
		place-self: center;
		opacity: 0.25;
	}
	@media screen and (max-width: 1450px) {
		grid-template-columns: 5% 45% 45% 5%;
	}
	@media screen and (max-width: 930px) {
		grid-template-columns: 15% 70% 15%;
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-top: 50px;
	margin-left: 90px;
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 32px;
}

.littleHeader a:link {
 text-decoration: none;
}


.blogPostContainer{
	margin: 60px 60px;
	@media screen and (max-width: 1450px) {
		margin: 60px;
	}
	@media screen and (max-width: 930px) {
		margin: 0px;
	}
}

.readMoreContainer{
	background-color: $cream;
	margin-bottom: 60px;
}

.blog{
	margin: 50px 0;
}

.hover {
	padding: 1px 0 ;
}


.hover:hover {
	background-color: $cream;
	div::after{
		/*content: "Read more";*/
		font-size: 18px;
		font-family: 'Playfair Display', serif;
		color: $tyrianPurple;
		font-weight: normal;
		cursor: pointer;
	}
}

.post1 {
	grid-column-start: 2;
	grid-column-end: 3;
	grid-row-start: 1;
	grid-row-end: 2;
}

.post2 {
	grid-column-start: 3;
	grid-column-end: 4;
	@media screen and (max-width: 930px) {
		display: none;
	}
	grid-row-start: 1;
	grid-row-end: 2;
}

.post3 {
	grid-column-start: 4;
	grid-column-end: 5;
	@media screen and (max-width: 1450px) {
		display: none;
	}
	grid-row-start: 1;
	grid-row-end: 2;
}

.arrowLeft {
	grid-column-start: 1;
	grid-column-end: 2;
	grid-row-start: 1;
	grid-row-end: 2;
	place-self: center;
	display: grid;
}

.arrowRight {
	grid-row-start: 1;
	grid-row-end: 2;
	grid-column-start: 5;
	grid-column-end: 6;
	place-self: center;
	display: grid;
	@media screen and (max-width: 1450px) {
		grid-column-start: 4;
		grid-column-end: 5;
	}
	@media screen and (max-width: 930px) {
		grid-column-start: 3;
		grid-column-end: 4;
	}
}
