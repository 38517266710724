@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */

.container {
	display: grid;
	grid-template-columns: auto;
	@media screen and (min-width: 930px) {
		grid-template-columns: 35% 65%;
	}

	@media screen and (max-width: 1450px) {
		grid-template-columns: auto;
	}

}

.headerContainer {
	@media screen and (max-width: 1450px) {
		display: grid;
		grid-template-columns: 65% 35%;
	}
	@media screen and (max-width: 930px) {
		grid-template-columns: auto;
	}}


.text {
	margin: 5em 35% 5em 5em;
	align-self: center;
	text-align: justify;

	@media screen and (max-width: 1450px) {
		margin: 50px 50px;
	}
}

.header {
	composes: header from "./main.module.scss";
	margin-top: 50px;
	margin-left: 100px;
	@media screen and (max-width: 930px) {
		margin-left: 50px;
		margin-right: 50px;
		text-align: center;
	}
}

.paragraph {
	composes: paragraphSimple from "./main.module.scss";
	font-size: 18px;
}

.littleHeader {
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
}

.headerImage {
	background-image: url("../assets/Stairs-to-tax-heaven-1920x400.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 85% 65%;
	width: 100%;
	height: 400px;
}

.headerImagePostTwo {
	background-image: url("../assets/Offshore-bank-1920x400.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 55%;
	width: 100%;
	height: 400px;
}

.headerImagePostThree {
	background-image: url("../assets/Rejected-by-banks-1920x400.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 15% 30%;
	width: 100%;
	height: 400px;
}

.headerImagePostFour {
	background-image: url("../assets/Offshore-company-1920x400.jpg");
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 50% 85%;
	width: 100%;
	height: 400px;
}

.author {
	composes: paragraph;
	opacity: 0.48;
	margin-left: 100px;
	@media screen and (max-width: 930px) {
		margin-left: 50px;
		margin-right: 50px;
		text-align: center;
	}
}

.shareButton {
	display: block;
	border-color: $tyrianPurple;
	border-style: solid;
	border-width: 2px;
	font-family: 'Playfair Display', serif;
	font-weight: normal;
	font-size: 24px;
	height: 3em;
	width: 10em;
	color: $tyrianPurple;
	margin: 100px 0 30px 100px;
	background-color: transparent;
	&:hover {
		border-color: rgba(74, 25, 61, 0.15);
	}
	@media screen and (max-width: 930px) {
		margin: 30px auto;
		text-align: center;
	}
}


.backButton {

	color: $tyrianPurple;
	font-family: 'Playfair Display', serif;
	font-weight: normal;
	font-size: 24px;
	background-color: transparent;
	border: none;
	text-align: left;
	margin: 0 0 0 100px;

	p {
		display: inline;
		margin-left: 50px;
	}

	&::before {
		content: " ";
		background-image: url('../assets/arrow-back.svg');
		background-repeat: no-repeat;
		background-size: auto 15px;
		background-position: 0 0.4em;
		opacity: 0.25;
		position: absolute;
		width: 100%;
		height: 100%;
	}

	@media screen and (max-width: 930px) {
		margin: 30px auto;
		text-align: center;
		display: block;
	}
}

.postImage {
	background-image: url('../assets/money.jpg');
	width: 100%;
	height: 400px;
}
