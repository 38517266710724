@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */
.container {
	composes: baseContainer from "./main.module.scss";
	background-color: $white;
}

.text{
	composes: baseText from "./main.module.scss";
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 3;
	}
}

.header{
	composes: header from "./main.module.scss";
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
}

.containerAboutUs{
	grid-column-start: 1;
	grid-column-end: 2;
	margin: 5% 50% 0 20%;
	@media screen and (max-width: 930px) {
		margin: 50px auto 0 auto;
	}
}
