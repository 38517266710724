@import './colors.scss';

/* autoprefixer grid: on */
.container{
	height: 100px;
}

.buttons{
	align-self: center;
	justify-self: end;
	margin-right: 50px;
	grid-column-start: 2;
	grid-column-end: 3;

	a {
		text-decoration: none;
	}

	p {
		display: inline;
	}

	@media screen and (max-width: 1450px) {
		width: 100vw;
		display: grid;
		grid-template-rows: auto auto auto auto;
		margin-top: 100px;

		a {
			margin: 30px auto;
		}

	}
}
.button{
	composes: navigationButton from "./buttons.module.scss";
}
.logo{
	margin-left: 0;
	grid-column-start: 2;
	grid-column-end: 3;
	align-self: center;

	svg {
		width: 200px;
	}

	@media screen and (min-width: 930px) {
		svg {
			height: 60px;
			width: 300px;
		}

		margin-left: 6em;
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.socialMedia{
	margin: auto auto;
	display:flex;
	justify-content:center;
	align-items:center;
	grid-column-start: 2;
	grid-column-end: 3;

	@media screen and (min-width: 1450px) {
		align-self: center;
		grid-column-start: 3;
		grid-column-end: 4;
	}
}

.select {
	align-self: center;
	text-transform: capitalize;
	grid-column-start: 1;
	grid-column-end: 2;
	position: relative;
	max-width: 80px;

	svg {
		width: 15px;
		height: 15px;
		margin-left: 10px;
	}

	@media screen and (min-width: 930px) {
		grid-column-start: 3;
		grid-column-end: 4;
	}
	@media screen and (min-width: 1450px) {
		grid-column-start: 4;
		grid-column-end: 5;
	}

}

:focus {
	outline:none;
}

::-moz-focus-inner {
	border:0;
}

.dropDown {
	position: absolute;
	align-self: center;
	composes: navigationButton from "./buttons.module.scss";
	margin: 0 0 0 10px;
	background-color: $white;
	width: 100%;

	p {
		margin-left:10px;
		margin-bottom: 10px;
		cursor: pointer;
		opacity: 0.5;
	}
}
.dropDownButton {
	composes: navigationButton from "./buttons.module.scss";
	margin: 0 0 0 18px;
	cursor: pointer;
}

.inline {
	display: inline;

	svg {
		display: inline;
	}
}

.browser {
	display: none;

	@media screen and (min-width: 1450px) {
		display: grid;
		grid-template-columns: 40% 40% 10% 10%;
		width: 99vw;
		height: 100px;
	}
}

.mobile {
	width: 100vw;
	height: 100%;
	display: grid;
	grid-template-columns: 20% 65% 15%;

	@media screen and (min-width: 930px) {
		display: none;
	}
}

.tablet {
	display: none;

	@media screen and (min-width: 930px) {
		width: 100vw;
		height: 100%;
		display: grid;
		grid-template-columns: 65% 15% 10% 10%;
		grid-template-rows: 100%;
	}

	@media screen and (min-width: 1450px) {
		display: none;
	}
}

.menuButton {
	align-self: center;
	cursor: pointer;
	grid-column-start: 3;
	grid-column-end: 4;

	svg {
		width: 40px;
		fill:none;
		stroke:#4a193d;
		stroke-width:3px;
	}

	@media screen and (min-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.center {
	align-self: center;
}

.openMenu {
	position: fixed;
	background-color: $white;
	height: 100vh;
	width: 100vw;
	left: 50%;
	margin-left: -50vw;
	margin-top: -50px;
	opacity: 0.9;
	z-index: 5;
}

.x {
	cursor: pointer;
	position: absolute;
	top:50%;
	margin-top: -47vh;
	right: 50%;
	margin-right: -47vw;

	svg {
		fill:none;
		stroke:#4a193d;
		stroke-width:3px;
		width: 50px;
	}
}
