
/* autoprefixer grid: on */

.container{
	display: grid;
	grid-template-columns: 20% 65% 15%;
	height: 150px;
	width: 100vw;
	overflow: hidden;
	@media screen and (max-width: 1450px) {
		height: 380px;
		grid-template-rows: auto auto;
		grid-template-columns: 20% 60% 20%;
	}
	@media screen and (max-width: 930px) {
		grid-template-columns: 20% 60% 20%;
	}
}

.buttons{
	align-self: center;
	justify-self: end;
	margin-right: 50px;
	grid-column-start: 2;
	grid-column-end: 3;
	a{
		text-decoration: none;
	}
	p{
		display: inline;
	}
	@media screen and (max-width: 1450px) {
		margin-top: 30px;
		place-self: center;
		justify-self: center;
		text-align: center;
		display: grid;
		grid-template-rows: auto auto auto auto auto auto auto;
		grid-column-start: 2;
		grid-column-end: 3;
		grid-row-start: 1;
		grid-row-end: 2;
		a{
			margin: 0.5em;
		}
	}
	@media screen and (max-width: 930px) {
		margin-right: 0;
	}
}
.button{
	composes: navigationButton from "./buttons.module.scss";
	font-size: 18px;
}
.logo {
	align-self: center;
	margin-left: 6em;
	grid-column-start: 1;
	grid-column-end: 2;
	svg {
		height: 40px;
	}

	@media screen and (max-width: 1450px) {
		grid-row-start: 2;
		grid-row-end: 3;
		margin-bottom: 50px;
	}

	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
		margin-left: 30px;
	}

}

.socialMedia{
	align-self: center;
	grid-column-start: 3;
	grid-column-end: 4;
	@media screen and (max-width: 1450px) {
		width: 150%;
		margin-left: -50%;
		grid-row-start: 2;
		grid-row-end: 3;
		grid-column-start: 3;
		grid-column-end: 4;
		margin-bottom: 50px;
	}
}
