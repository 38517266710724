@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url("https://use.typekit.net/qil5hjr.css");


/* autoprefixer grid: on */

.container {
	display: grid;
	grid-template-columns: 35% 65%;

	@media screen and (max-width: 1450px) {
		grid-template-columns: 50% 50%;
	}

	@media screen and (max-width: 930px) {
		grid-template-columns: 100%;
	}
}

.contactUsContainer{
	composes: container;
	background-color: $cream;


}

.letsTalkContainer{
	composes: container ;

	:first-child {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.email {
	grid-column-start: 2;
	grid-column-end: 3;
}

.number {
	grid-column-start: 2;
	grid-column-end: 3;
}

.second {
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.decidingContainer{
	composes: container ;
	background-color: $cream;
}

.contactPicture{
	margin-right: 600px;
	grid-column-start: 1;
	grid-column-end: 2;
	position: relative;

	svg{
		position: absolute;
		height: 120px;
		width: 150px;
		margin: 110px 0 0 160px;
		z-index: 0;
	}

	h1{
		position: relative;
	}
}

.text{
	margin: 5em 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;

	button {
		border-color: $tyrianPurple;
		border-style: solid;
		border-width: 2px;
		font-family: 'Playfair Display', serif;
		font-weight: normal;
		font-size: 24px;
		height: 3em;
		width: 15em;
		margin-top: 30px;
		color: $tyrianPurple;
		background-color: transparent;
	}

	@media screen and (max-width: 1450px) {
		margin: 50px 30px 50px 0;
	}

	@media screen and (max-width: 930px) {
		margin: 0 30px 5em 30px;
		grid-column-start: 1;
		grid-column-end: 2;
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	z-index: 3;
	margin-right: 500px;
	@media screen and (max-width: 930px) {
		margin: 30px 30px 10px 30px;
		text-align: center;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
	@media screen and (max-width: 930px) {
		margin: 30px;
		text-align: center;
	}
}

.contactEmail{
	composes: contactEmail from "./letsTalk.module.scss";
	margin-right: 50px;
}

.contactNumber{
	composes: contactNumber from "./letsTalk.module.scss";
	margin-right: 50px;
}

.form{
	margin:50px 0;
	input{
		border: none;
		background-color: $cream;
		color: $tyrianPurple;
		font-family: futura-pt, sans-serif;
		font-size: 18px;
		width: 500px;
		height: 3em;
		margin: 15px;
		padding: 0 0 0 25px;
	}
	textarea {
		border: none;
		background-color: $cream;
		color: $tyrianPurple;
		font-family: futura-pt, sans-serif;
		font-size: 18px;
		width: 100%;
		height: 24em;
		margin: 15px;
		padding: 1em 0 0 25px;
		resize: none;
		overflow: auto;
	}
	::placeholder {
		opacity: 1;
		color: $tyrianPurple;
	}

	button{
		border: none;
		background-color: $tyrianPurple;
		color: $white;
		font-family: 'Playfair Display', serif;
		font-size: 24px;
		height: 3em;
		width: 525px;
		margin: 15px;
	}

	@media screen and (max-width: 1450px) {
		margin: 30px 0;

		input {
			width: 330px;
		}

		textarea {
			width: 330px;
		}

		button{
			width: 355px;
		}
	}

	@media screen and (max-width: 930px) {
		margin: 30px 30px;

		input {
			width: 80%;
		}

		textarea {
			width: 80%;
		}

		button{
			width: 80%;
		}
	}
}

.servicesContainer{
	display: grid;
	grid-template-columns: 33% 33% 33%;
	grid-template-rows: 50% 50%;
	font-family: 'Playfair Display', serif;
	font-weight: normal;
	font-size: 28px;
	color: $tyrianPurple;
	position: relative;

	:nth-child(3n+1) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
	:nth-child(3n+2) {
		grid-column-start: 2;
		grid-column-end: 3;
	}
	:nth-child(3n+3) {
		grid-column-start: 3;
		grid-column-end: 4;
	}
	:nth-child(4), :nth-child(5) {
		grid-row-start: 2;
		grid-row-end: 3;
	}

	div {
		margin: 30px;
	}

	svg {
		height: 95px;
		width: 95px;
		opacity: 0.05;
		position: absolute;
		margin: -10px 0 0 -40px;
	}

	@media screen and (max-width: 1450px) {
		grid-template-columns: 50% 50%;
		grid-template-rows: 33% 33% 33%;
		:nth-child(2n+1) {
			grid-column-start: 1;
			grid-column-end: 2;
		}
		:nth-child(2n+2) {
			grid-column-start: 2;
			grid-column-end: 3;
		}
		:nth-child(3), :nth-child(4) {
			grid-row-start: 2;
			grid-row-end: 3;
		}
		:nth-child(5) {
			grid-row-start: 3;
			grid-row-end: 4;
		}
	}

}
