@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
/* autoprefixer grid: on */

.container {
	composes: baseContainer from "./main.module.scss";
	grid-template-rows: auto auto;
}

.container1{
	composes: container;
	background-color: $cream;
}

.container2{
	composes: container;
	background-color: $white;
}

.aboutUsContainer{
	align-self: center;
	svg{
		height: 120px;
		opacity: 0.05;
		position: absolute;
		margin: 7em 0 0 12em;
	}
	@media screen and (max-width: 930px) {
		align-self: start;
		justify-self: center;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 1;
		grid-row-end: 2;
		margin: auto 50px;
		text-align: center;
		svg{
			height: 120px;
			opacity: 0.05;
			position: absolute;
			margin: 100px 0 0 40px;
		}
	}
}

.text{
	margin: 5em 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		align-self: start;
		justify-self: center;
		grid-column-start: 1;
		grid-column-end: 2;
		grid-row-start: 2;
		grid-row-end: 3;
		margin: auto 50px;
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	@media screen and (max-width: 930px) {
		margin-left: 0;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 18px;
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
}
