
/* autoprefixer grid: on */

.container{
	display: grid;
	grid-template-columns: 33% 33% 33%;
	margin: 40px;

	:nth-child(3n+1) {
		grid-column-start: 1;
		grid-column-end: 2;
	}

	:nth-child(3n+2) {
		grid-column-start: 2;
		grid-column-end: 3;
	}

	:nth-child(3n+3) {
		grid-column-start: 3;
		grid-column-end: 4;
	}

	:nth-child(4),:nth-child(5),:nth-child(6) {
		grid-row-start: 2;
		grid-row-end: 3;
	}

	:nth-child(7),:nth-child(8),:nth-child(9) {
		grid-row-start: 3;
		grid-row-end: 4;
	}

	@media screen and (max-width: 1450px) {
		grid-template-columns: 50% 50%;
		:nth-child(2n+1) {
			grid-column-start: 1;
			grid-column-end: 2;
		}

		:nth-child(2n+2) {
			grid-column-start: 2;
			grid-column-end: 3;
		}

		:nth-child(3),:nth-child(4) {
			grid-row-start: 2;
			grid-row-end: 3;
		}

		:nth-child(5),:nth-child(6) {
			grid-row-start: 3;
			grid-row-end: 4;
		}

		:nth-child(8),:nth-child(7) {
			grid-row-start: 4;
			grid-row-end: 5;
		}

		:nth-child(9) {
			grid-row-start: 6;
			grid-row-end: 7;
		}
	}

	@media screen and (max-width: 930px) {
		grid-template-columns: auto;
		margin: 20px;

		:nth-child(n+1) {
			grid-column-start: 1;
			grid-column-end: 2;
			grid-row-start: auto;
			grid-row-end: auto;
		}
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-top: 50px;
	margin-left: 90px;
	@media screen and (max-width: 930px) {
		margin: 30px auto 0 auto;
	}
}
