@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');

/* autoprefixer grid: on */
.container {
	composes: baseContainer from "./main.module.scss";
}

.mainContainer{
	composes: container;
	background-color: $cream;
	button{
		border-color: $tyrianPurple;
		border-style: solid;
		border-width: 2px;
		font-family: 'Playfair Display', serif;
		font-weight: normal;
		font-size: 24px;
		height: 3em;
		width: 15em;
		margin-top: 30px;
		color: $tyrianPurple;
		background-color: transparent;
		margin-left: 90px;
		margin-bottom: 50px;
	}

	svg{
		height: 100px;
		opacity: 0.1;
		position: absolute;
		margin: -150px 0 0 340px;
	}

	@media screen and (max-width: 1450px) {
		svg {
			margin: 160px 0 0 270px;
		}

		button {
			width: 12em;
			margin: auto;
			place-self: center;
		}
	}

	@media screen and (max-width: 930px) {
		margin: 0 0 0 0;
		display: grid;
		grid-template-columns: auto;
		grid-template-rows: auto auto;
		grid-column-start: 1;
		grid-column-end: 2;
		button{
			margin: auto;
			place-self: center;
			width: 15em;
		}
		svg{
			margin: 80px 0 0 50px;
		}
	}

}

.headerText{
	@media screen and (max-width: 1450px) {
		display: grid;
		position: relative;
		grid-template-rows: 50% 50%;
	}
	@media screen and (max-width: 930px) {
		grid-row-start: 1;
		grid-row-end: 2;
		display: grid;
		position: relative;
	}
}

.servicesContainer{
	composes: container;
	margin-right: 50px;
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	margin-right: 50px;
	@media screen and (max-width: 930px) {
		margin-left: 50px;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 18px;
	@media screen and (max-width: 930px) {
		margin: 30px 50px;
	}
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
	@media screen and (max-width: 930px) {
		margin: 30px 50px;
	}
}

.text{
	margin: 5em 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		grid-row-start: 2;
		margin: auto auto;
		grid-row-end: 3;
		grid-column-start: 1;
		grid-column-end: 2;
		margin-bottom: 30px;
	}
}

.countries{
	composes: paragraph;
	list-style-type: none;
	a{
		color: $tyrianPurple;
		text-decoration: none;
	}
}

.firstColumn{
	margin-left: 50px;

	@media screen and (min-width: 1450px) {
		margin-right: 100px;
	}
}

.table{
	composes: paragraph;
	border-spacing: 0;
	tr{
		background-color: $cream;
		height: 3em;
	}
	td{
		padding:20px;
	}
	tr:nth-child(even) {
		background-color: $whiteCream;
	}
	@media screen and (max-width: 930px) {
		td{
			padding: 18px 10px;
		}
	}
}

.warning{
	composes: paragraph;
	background-color: $infoBg;
	width: 75%;
	margin: 50px 50px 50px 0;
	color: $info;
	p{
		padding: 0.8em 0 1em 1.5em;
	}
	@media screen and (max-width: 930px) {
		margin: 30px 50px;
		width: 90%;
	}
}

.secondColumn {
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}
