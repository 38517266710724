@import './colors.scss';
@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url("https://use.typekit.net/qil5hjr.css");


/* autoprefixer grid: on */

.container{
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
  grid-template-rows: 50% 50%;
  @media screen and (max-width: 1450px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 33.3% 33.3% 33.3%;
  }
  @media screen and (max-width: 930px) {
    grid-template-columns: auto;
    grid-template-rows: auto auto auto auto auto auto;
  }
}

.headerCointainer{
  background-color: $cream;
  color: $tyrianPurple;
  display: grid;
  grid-template-rows: auto auto;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;

  button {
    grid-row-start: 2;
    grid-row-end: 3;
    border-color: $tyrianPurple;
    border-style: solid;
    border-width: 2px;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    height: 3em;
    width: 10em;
    color: $tyrianPurple;
    margin: 150px 2em 2em 100px;
    background-color: transparent;
  }

  button:hover {
    color: $tyrianPurple;
    border-color: rgba(74, 25, 61, 0.15);
  }

  @media screen and (max-width: 1450px) {

    h1 {
      margin: 50px 2em;
    }

    button {
      margin: 150px auto 50px auto;
    }
  }

  @media screen and (max-width: 930px) {

    h1 {
      margin: 50px auto;
    }

    button {
      margin: 150px auto 2em auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
    }
  }
}

.image{
  position: absolute;
  svg{
    height: 100px;
    opacity: 0.05;
    grid-row-start: 1;
    grid-row-end: 3;
    position: absolute;
    margin: 40px 0 0 50px;
  }

}

.serviceContainer{
  display: grid;
  grid-template-rows: 33% 33% 33%;
  min-height: 500px;
  height: 500px;
  color: $tyrianPurple;

  h1 {
    grid-row-start: 1;
    grid-row-end: 2;
  }

  p {
    grid-row-start: 2;
    grid-row-end: 3;
  }

  button{
    color: $tyrianPurple;
    font-family: 'Playfair Display', serif;
    font-weight: normal;
    font-size: 24px;
    background-color: transparent;
    border: none;
    text-align: left;
    margin: 0 2em 0 100px;
    grid-row-start: 3;
    grid-row-end: 4;
    p{
      display: inline;
    }
  }
  button::before{
    content: " ";
    background-image: url('../assets/arrow.svg');
    background-repeat: no-repeat;
    background-size: auto 15px;
    background-position: 7em 0.4em;
    opacity: 0.25;
    position: absolute;
    width: 20%;
    height: 20%;
  }
}

.whiteContainer {
  composes: serviceContainer;
  background-color: $whiteCream;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  @media screen and (max-width: 930px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

.whiteContainer2 {
  composes: serviceContainer;
  background-color: $whiteCream;
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  @media screen and (max-width: 1450px) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media screen and (max-width: 930px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 4;
    grid-row-end: 5;
  }

}

.whiteContainer3 {
  composes: serviceContainer;
  background-color: $whiteCream;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 2;
  grid-row-end: 3;
  @media screen and (max-width: 1450px) {
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media screen and (max-width: 930px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;
  }
}

.creamContainer2 {
  composes: serviceContainer;
  background-color: $cream;
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 2;
  grid-row-end: 3;

  @media screen and (max-width: 1450px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media screen and (max-width: 930px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

.creamContainer{
  composes: serviceContainer;
  background-color: $cream;
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 2;


  @media screen and (max-width: 1450px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media screen and (max-width: 930px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }

}

.header{
  composes: header from "./main.module.scss";
  margin: auto 50px;

  @media screen and (min-width: 930px) {
    text-align: center;

  }

  @media screen and (min-width: 1450px) {
  margin: 50px 30px 0 90px;
  text-align: left;

  }
}

.paragraph{
  composes: paragraph from "./mainAbout.module.scss";
  margin: 60px 150px 0 100px;
  @media screen and (max-width: 1000px) {
    margin: auto 30px 0 30px;
  }
  @media screen and (max-width: 400px) {
    margin: auto 50px 0 0;
  }
}

.littleHeader{
  composes: littleHeader from "./mainAbout.module.scss";
  margin: 1em 130px 0 100px;
  @media screen and (max-width: 1400px) {
    margin: 1em 90px 0 90px;
  }
  @media screen and (max-width: 1000px) {
    margin: 1em 20px 0 20px;
  }
  @media screen and (max-width: 400px) {
    margin: auto 50px;
  }
}
