@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url("https://use.typekit.net/qil5hjr.css");
@import './colors.scss';
/* autoprefixer grid: on */

.container {
	display: grid;
	margin: auto auto;
	background-color: $cream;

	@media screen and (min-width: 930px) {
		grid-template-columns: auto;
	}

	@media screen and (min-width: 1450px) {
		grid-template-columns: 50% 50%;
	}
}

.container svg {
	margin: 50px auto 0 auto;
	align-self: center;
	justify-self: center;
	max-width: 340px;
	max-height: 340px;
	width: 340px;
	height: 340px;
	@media screen and (min-width: 450px) {
		max-width: 420px;
		max-height: 420px;
		width: 420px;
		height: 420px;
	}
	@media screen and (min-width: 930px) {
		max-width: 500px;
		max-height: 500px;
		width: 500px;
		height: 500px;
	}
	@media screen and (min-width: 1450px) {
		margin: 50px;
		max-width: 500px;
		max-height: 500px;
	}
}

.bankImage {
	display: grid;
	width: 100%;
	grid-column-start: 1;
	grid-column-end: 2;
	align-self: center;
	justify-self: center;
}

.baseText {
	margin: 50px;
	align-self: center;

	@media screen and (min-width: 930px) {
		margin: 50px 15% 50px 0;
	}

	@media screen and (min-width: 1450px) {
		margin: 5em 35% 5em 0;
	}
}

.text {
	composes: baseText;
	margin: 50px 15% 50px 15%;

	@media screen and (min-width: 930px) {
		grid-column-start: 2;
		grid-column-end: 3;
	}

	@media screen and (min-width: 1450px) {
		margin: 5em 35% 5em 0;
	}

}

.headerSimple {
	font-family: 'Playfair Display', serif;
	color: $tyrianPurple;
	font-weight: normal;
}

.headerSimple a, a:link{
	font-family: 'Playfair Display', serif;
	color: $tyrianPurple;
	font-weight: normal;
	text-decoration: none;
}

.header {
	composes: headerSimple;
	font-size: 55px;
	margin: auto auto;
	text-align: center;

	@media screen and (min-width: 930px) {
		text-align: left;
	}
}

.paragraphSimple {
	color: $tyrianPurple;
	font-family: futura-pt, sans-serif;
}

.paragraph {
	composes: paragraphSimple;
	font-size: 22px;
}

.button {
	composes: simpleButton from "./buttons.module.scss";
	width: 100%;

	@media screen and (min-width: 930px) {
		width: 15em;
	}
}

.button:hover {
	background-color: rgba(74, 25, 61, 0.5);
}

.littleHeader {
	composes: header;
	margin: 30px 30px 0 30px;

	@media screen and (min-width: 930px) {
		margin-left: 100px;
	}
}

.baseContainer {
	display: grid;
	grid-template-columns: auto;

	@media screen and (min-width: 930px) {
		grid-template-columns: 50% 50%;
	}

	@media screen and (min-width: 1450px) {
	grid-template-columns: 35% 65%;
	}
}
