@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url("https://use.typekit.net/qil5hjr.css");
@import './colors.scss';

/* autoprefixer grid: on */

.navigationButton{
	border: none;
	color: $tyrianPurple;
	background-color: transparent;
	font-family: futura-pt, sans-serif;
	font-weight: 450;
	font-size: 20px;
	margin: auto 25px;
}

.simpleButton{
	border: none;
	color: $white;
	background-color: $tyrianPurple;
	font-family: 'Playfair Display', serif;
	font-size: 24px;
	height: 3em;
	width: 15em;
}
