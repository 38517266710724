@import url('https://fonts.googleapis.com/css?family=Playfair+Display');
@import url("https://use.typekit.net/qil5hjr.css");
@import './colors.scss';


/* autoprefixer grid: on */

.container {
	composes: baseContainer from "./main.module.scss";
}

.container1{
	composes: container;
	background-color: $cream;
}

.container2{
	composes: container;
	background-color: $white;
	margin: 3% 0 5% 3%;
	@media screen and (max-width: 1450px) {
		grid-template-columns: auto;
	}
}

.faqContainer{
	align-self: center;
	margin: auto 30px;
	svg{
		height: 210px;
		opacity: 0.05;
		position: absolute;
		margin: 8em 0 0 15em;
	}
	@media screen and (max-width: 930px) {
		svg{
			 margin: 30px 0 0 50%;
		 }
	}
}

.text{
	margin: 5em 35% 5em 0;
	align-self: center;
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 930px) {
		grid-column-start: 1;
		grid-column-end: 2;
		margin: 30px 50px;
	}
}

.header{
	composes: header from "./main.module.scss";
	margin-bottom: 100px;
	margin-top: 50px;
	margin-left: 90px;
	@media screen and (max-width: 930px) {
		margin: 50px auto;
		display: block;
	}
}

.paragraph{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 18px;
}

.category{
	composes: paragraphSimple from "./main.module.scss";
	font-size: 20px;
	@media screen and (max-width: 1450px) {
		display: none;
	}
}

.categoryDropDown{
	display: none;
	@media screen and (max-width: 1450px) {
		display: block;
		margin-top: 30px;
	}
	@media screen and (max-width: 930px) {
		margin-top: 0;
	}
}

.dropDownContainer{

	@media screen and (max-width: 1450px) {
		composes: paragraphSimple from "./main.module.scss";
		font-size: 20px;
		display: grid;
		grid-template-columns: 60% 40%;
	}
	@media screen and (max-width: 930px) {
		grid-template-columns: auto;
		text-align: center;
	}
}

.dropDown{
	position: absolute;
	opacity: 0.5;
	background-color: $white;
	width: 200px;
	@media screen and (max-width: 930px) {
		text-align: center;
		margin-left: auto;
		margin-right: auto;
		left: 0;
		right: 0;
	}
}

.littleHeader{
	composes: headerSimple from "./main.module.scss";
	font-size: 40px;
	margin-bottom: 1em;
	@media screen and (max-width: 930px) {
		text-align: center;
	}
}

.questions{
	margin-right: 5em;

	h2 {
		font-family: 'Playfair Display', serif;
		color: $tyrianPurple;
		font-weight: normal;
		font-size: 20px;
		margin-left: 40px;
	}

	p {
		color: $tyrianPurple;
		font-family: futura-pt, sans-serif;
		font-size: 18px;
		margin-left: 40px;
		margin-right: 80px;
	}

	@media screen and (max-width: 930px) {
		margin: auto 50px;
	}
}

.button{
	background-color: $cream;
	border: none;
	width: 100%;
	text-align: left;
	display: grid;
	grid-template-columns: 90% auto;
	margin-bottom: 1em;

	p {
		font-family: 'Playfair Display', serif;
		color: $tyrianPurple;
		font-weight: normal;
		font-size: 20px;
		margin: 30px;
		grid-column-start: 1;
		grid-column-end: 2;
	}

	svg {
		min-height: 8px;
		height: 8px;
		width: 15px;
		min-width: 15px;
		margin-right: 1em;
		margin-top: auto;
		margin-bottom: auto;
		justify-self: end;
		grid-column-start: 2;
		grid-column-end: 3;
	}

	@media screen and (max-width: 400px) {
		width: 100%;
		margin-bottom: 1em;
	}
}

.first {
	grid-column-start: 1;
	grid-column-end: 2;
}

.second {
	grid-column-start: 2;
	grid-column-end: 3;
	@media screen and (max-width: 1450px) {
		grid-column-start: 1;
		grid-column-end: 2;
	}
}
